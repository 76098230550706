import image1 from '../assets/images/ns_tunnel.png';
const siteOptionData = [
    {
      id: 1,
      siteName: "NS TUNNEL BRAND",
      image: image1,
      description: "Experience a secure and reliable VPN service with enhanced privacy and performance.",
      siteLink: "https://nstunnelvpn.xyz/login"
    }
  ];
  export default siteOptionData;